var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Chick Placement Entry ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"action":"apps-data-entry-list","method":"post"},on:{"submit":_vm.addChickPlacement}},[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-end mb-0 mt-2 mr-2"},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Total Alive Birds : "+_vm._s(this.total_chick ? _vm.total_chick : 0)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Hatch"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Select Feed/Supplement Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.hatchList,"label":"hatch_no","clearable:false":"","reduce":function (e) { return e.hatch_no; }},on:{"input":_vm.getHatchDetails},model:{value:(_vm.FormData.hatch_no),callback:function ($$v) {_vm.$set(_vm.FormData, "hatch_no", $$v)},expression:"FormData.hatch_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Birds Available"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Birds Available","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Birds Available","type":"text","id":"quantity","name":"birds_available","disabled":""},model:{value:(_vm.hatchInfo.quantity),callback:function ($$v) {_vm.$set(_vm.hatchInfo, "quantity", $$v)},expression:"hatchInfo.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chick Rate (Rs.)"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Chick Rate (Rs.)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":" Chick Rate(Rs.)","type":"text","name":"Chick Rate (Rs.)","disabled":""},model:{value:(_vm.hatchInfo.chick_rate),callback:function ($$v) {_vm.$set(_vm.hatchInfo, "chick_rate", $$v)},expression:"hatchInfo.chick_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chick Placed Quantity "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"No. of Chick Placed ","rules":{
											required: true,
											max_value: _vm.hatchInfo.quantity,
											regex: RegExp(/^([0-9]+)$/),
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"No. of Chick Placed ","type":"text","name":"chick_placed"},model:{value:(_vm.FormData.chick_placed),callback:function ($$v) {_vm.$set(_vm.FormData, "chick_placed", $$v)},expression:"FormData.chick_placed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }